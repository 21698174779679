<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox product-wrapper">
    <Banner title="互联网医院" describe="互联网医院就是用信息化、互联网化的手段去最大限度降低成本、优化服务流程，提升医疗服务诊前、诊中、诊后的全流程质量。互联网医院作为互联网医疗服务的承载形式之一，区别于公立医疗服务机构以及民营医疗服务机构。 同时还延伸出了很多新的服务态势，包括远程医疗、在线问诊、家庭医生签约服务等。包括了以互联网为载体和技术手段的健康教育、医疗信息查询、电子健康档案、疾病风险评估、在线疾病咨询、电子处方、远程会诊、远程治疗和康复等多种形式的健康医疗服务。" />
    <div class="product-content">
      <div class="product-name">产品亮点</div>
      <div class="introduction-content">
        <img src="@/assets/product/netHospital1.png" class="img1" alt="">
        <img src="@/assets/product/netHospital2.png" class="img2" alt="">
      </div>
    </div>
  </div>
</template>

<script>

import Banner from '@/components/Banner';
export default {
  name: '',
  components: {
    Banner
  },
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.product-wrapper {
  .product-content {
    background: #fff;
    padding-bottom: 240px;
  }
  .introduction-content {
    width: 1342px;
    margin: 0 auto;
    text-align: center;
    .img1 {
      width: 100%;
      height: 492px;
      margin-bottom: 60px;
    }
    .img2 {
      width: 1267px;
      height: 576px
    }
  }
}
</style>
